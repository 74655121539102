import React from "react";
import "../css/Contact.css";

function Contact() {
  window.scrollTo(0, 0);

  return (
    <div className="contact">
      <div className="contact-left-flex">
        <div className="contact-map">
          <h3>Location</h3>
          <iframe
            class="gmap_iframe"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=10-A Valluvar Street, Karur – 639001, Tamilnadu , India.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
      <div className="contact-right-flex">
        <h3>Contact Us</h3>
        <div className="contact-section">
          <h4>Anand Traders</h4>
          <p>10-A Valluvar Street, Karur – 639001, Tamilnadu , India.</p>
        </div>
        <div className="contact-section">
          <h4>Phone Number</h4>
          <p>Mr. R.Selvan - +91-9843056449</p>
          <p>Mr. P.R.Anand - +91-9443268449</p>
        </div>
        <h4>Email Id</h4>
        <p>selvan@selvantex.com</p>
      </div>
    </div>
  );
}
export default Contact;
