import React from "react";
import { Link } from "react-router-dom";

import "../css/Footer.css";
function Footer() {
  return (
    <div className="container-fluid">
      <div className="Footer">
        <div className="footer-left">
          <h4 className="footer-title">Address</h4>
          <div className="footer-right-one">
            <p>Anand Traders</p>
            <p>10-A Valluvar Street , Karur – 639001.</p>
            <p>Tamil Nadu , India</p>
          </div>
        </div>
        <div className="footer-middle">
          <h4 className="footer-title">Company</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about ">About Us</Link>
            </li>
            <li>
              <Link to="/process">Process</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="footer-middle-two">
          <h4>Location</h4>
          <iframe
            class="gmap_iframe"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=10-A Valluvar Street, Karur – 639001, Tamilnadu , India.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
        {/* <div className="footer-right">
          <h4 className="footer-title">Follow Us</h4>
          <a href="#" target="blank">
            <img className="facebook" src={image1}></img>
          </a>
          <a href="#" target="blank">
            <img className="instagram" src={image2}></img>
          </a>
          <a href="#">
            <img className="twitter" src={image3}></img>
          </a>
          <a href=" #" target="blank">
            <AiFillLinkedin size="40px" color="white" className="linkedin" />
          </a>
        </div> */}
      </div>
    </div>
  );
}
export default Footer;
